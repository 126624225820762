<template>
  <div class="head headnonBg">
    <a @click="goBack"><!--img src="@/assets_mobile/img/icon_arrow.svg"--><h2><img src="@/assets_mobile/img/newmainNameIcon.svg"><slot></slot></h2></a>
    <!--a @click="goPageByName('mobileMain')"><img src="@/assets_mobile/img/icon_home.png" /></a-->
  </div>
</template>

<script>
export default {
  name: 'NaviBar'
}
</script>

<style scoped src="@/styles_mobile/subcommon.css"></style>

<style scoped>
  .headnonBg { background: #f5f5f5 }
</style>
